import Vue from "vue";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import DatePicker from "@/components/Content/DatePicker.vue";
// @ts-ignore
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
// @ts-ignore
import { itemsReportType, dataReports } from "@/views/Admin/ReportsOnDemand/Logic/Utils";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";
import { isEmpty, debounce, includes } from "lodash";
import {
	isRequired,
	isAfterCompare,
	isMaxDays,
	//@ts-ignore
} from "@/services/rule-services";
//@ts-ignore
import moment from "moment-timezone";
//@ts-ignore
import { getPermisionReport } from "@/utils/permissionResolve";

const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD";

export default Vue.extend({
name: "ReportD",
props: {
    title:{
        type: String,
        default: ""
    },
    type:{
        type: String,
        required: true
    },
    keyRoute:{
        type: String,
        required: true
    },
    searchDates:{
        type: Boolean,
        default: false
    }
},
components: {
    Separator,
    CardAutocomplete,
    DatePicker,
    DataPickerStarTime,
    CardActions
},
data: () => ({
    valid: false,
    typesReport: {},
    report: {},
    resources: {},
    loading: {},

    countryTerm: "",
    campaignTerm: "",
    listTerm: "",

    selected_all:{
        country: [],
        campaign:[],
        list_pois:[]
    },

    rules:{
        country_rule: [],
        campiagn_rule: [],
        list_pois_rule: [],
        start_date_rule: [],
        end_date_rule: [],
    },
    errors:{
        country_id: [],
        campaign_id:[]
    },
    disabled:{
        run: false
    }
}),
created(){},
mounted(){
    this.$nextTick(async () => {
        await this.loadData();
        this.canCreate();
    });
},
computed:{

    ...mapGetters("profile", ["getAbility"]),

    isVisible(){
        return (item) => { 
            if(isEmpty(this.typesReport)) return false;
            return this.typesReport.values.includes(item);
        };
    },

    isMultiSelected(){
        return (item) => {
            if(isEmpty(this.typesReport)) return false;
            return this.typesReport.multiselect[item];
        }
    },

    getRules() {
        return {
            isRequired,
            isAfterCompare,
            isMaxDays,
        };
    },

    getPermission(){
        return getPermisionReport();
    }

},
methods:{
    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("report", [
        "fetchCampaign", 
        "fetchListPois", 
        "fetchCountryStoreAttribution",
        "fetchCountryCampaign",
        "createReportOnDemand"
    ]),
    ...mapActions("campaign", ["getCampaign"]),

    syncData(key: string, term: string) {
        this[`${key}Term`] = term;
    },

    canCreate(){
        switch(this.type){
            case "store_attribution_report":
                this.disabled.run = !this.getAbility.can(this.getPermission.actions.report_store_attribution, this.getPermission.subject);
                break;
            case "geo_report":
                this.disabled.run = !this.getAbility.can(this.getPermission.actions.report_geo, this.getPermission.subject);
                break;
            case "campaign_insight":
                this.disabled.run = !this.getAbility.can(this.getPermission.actions.report_campaign_insight, this.getPermission.subject);
                break;
            case "unique_devices_report":
                this.disabled.run = !this.getAbility.can(this.getPermission.actions.report_unique_devices, this.getPermission.subject);
                break;
            default:
                this.disabled.run = false;
                break;
        }
        
    },

    async loadData(){
        await this.setLoadingData(TypeLoading.loading);
        this.typesReport = itemsReportType()[this.type];
        this.report = dataReports()[this.type].model;
        this.resources = dataReports()[this.type].resources;
        this.loading = dataReports()[this.type].loading;
        await this.setLoadingData();
    },

    async validate() {
        return await this.$refs.form.validate();
    },

    async validCampaignInsight(){
        if(isEmpty(this.report.campaign_id)){
            this.errors.campaign_id = ["This field is required"];
        }
        if(isEmpty(this.report.country_id)){
            this.errors.country_id = ["This field is required"];
        }
        return isEmpty(this.errors.campaign_id) && isEmpty(this.errors.country_id);
    },

    async clearValidCampaignInsight(){
        this.errors.campaign_id = [];
        this.errors.country_id = [];
    },

    async addRules(type){
        switch(type){
            case "store_attribution_report":
                this.rules.country_rule = [this.getRules.isRequired];
                this.rules.campiagn_rule = [this.getRules.isRequired];
                this.rules.list_pois_rule = [this.getRules.isRequired];
                break;
            case "geo_report":
                this.rules.campiagn_rule = [this.getRules.isRequired];
                this.rules.start_date_rule = [this.getRules.isRequired];
                this.rules.end_date_rule = [this.getRules.isRequired];
                break;
            case "campaign_insight":
                break;
            case "unique_devices_report":
                this.rules.campiagn_rule = [this.getRules.isRequired];
                this.rules.start_date_rule = [this.getRules.isRequired];
                this.rules.end_date_rule = [this.getRules.isRequired];
                break;
        }
        
    },

    async clearRules(){
        this.rules.country_rule = [];
        this.rules.campiagn_rule = [];
        this.rules.list_pois_rule = [];
        this.rules.start_date_rule = [];
        this.rules.end_date_rule = [];
    },  

    async fetchCampaing(paramsTerm){
        let params = {
            custom_key: "external_id",
            custom_value: "name",
            term: paramsTerm?.term
        }
        this.loading.campaign = true;
        await this.fetchCampaign(params)
        .then((response) => {
            this.resources.campaign = response;
            this.loading.campaign = false;
        })
        .catch((err) => {
            this.loading.campaign = false;
        });
    },

    async fetchCountrys(paramsTerm){
        let params = {
            custom_key: "numeric_code",
            custom_value: "name",
            term: paramsTerm?.term
        }
        if(this.type == "store_attribution_report"){
            this.loading.country = true;
            await this.fetchCountryStoreAttribution(params)
            .then((response) => {
                this.resources.country = response;
                this.loading.country = false;
            })
            .catch((err) => {
                this.loading.country = false;
            });
        }else{
            this.loading.country = true;
            await this.fetchCountryCampaign(params)
            .then((response) => {
                this.resources.country = response;
                this.loading.country = false;
            })
            .catch((err) => {
                this.loading.country = false;
            });
        }
    },

    async fetchListPOIS(paramsTerm){
        let params = {
            custom_key: "external_id",
            custom_value: "name",
            term: paramsTerm?.term
        }
        this.loading.list_pois = true;
        await this.fetchListPois(params)
        .then((response) => {
            this.resources.list_pois = response;
            this.loading.list_pois = false;
        })
        .catch((err) => {
            this.loading.list_pois = false;
        });
    },

    async preparedDataReport(){
        if(this.report.hasOwnProperty("end_date")){
            this.report.end_date = moment(this.report.end_date).format(DEFAULT_DATE_TIME_FORMAT);
        }
        if(this.report.hasOwnProperty("start_date")){
            this.report.start_date = moment(this.report.start_date).format(DEFAULT_DATE_TIME_FORMAT);
        }
    },

    async isSelectedAll(value: Array<number>) {
        return includes(value, 0);
    },

    async handleSelectedAll(keyReport: any, key: any, value: Array<any>) {
        this.selected_all[key] = value;

        let itemsValues = new Array();
        if(await this.isSelectedAll(value)){
            itemsValues = this.resources[key].map((v) => v.id);
        }

        this.report[keyReport] = itemsValues;
    },

    async handleSelectedItem(key: any, value: Array<any>) {
        const equal = this.resources[key].length === value.length;
        if(value.length == 0)
        {	
            this.selected_all[key] = [];
        }else{
            this.selected_all[key] = equal ? [0] : [];
        }
    },

    async handleAction(){
        try {
            if(this.type == "campaign_insight"){
                if(!(await this.validCampaignInsight())) return;
            }else{
                await this.addRules(this.type);
                if (!(await this.validate())) return;
            }
            await this.setLoadingData(TypeLoading.loading);
            await this.preparedDataReport();
            await this.createReportOnDemand({KEY_ROUTE: this.keyRoute, DATA: this.report})
            .then((response) => {
                this.setLoadingData();
            })
            .catch((err) => {
                this.setLoadingData();
            });
            await this.clearRules();
            await this.clearValidCampaignInsight();
        } catch (error) {
            await this.setLoadingData();
            await this.clearRules();
        }
    },
    
    async searchDatesFromCampaign(idCampaign: any){
        let idValue = this.resources.campaign.find((c) => c.id == idCampaign).extra;
        if(idValue){
            this.loading.campaign = true;
            await this.getCampaign(idValue)
            .then((response) => {
                this.loading.campaign = false;
                this.report.start_date = response.start_date;
                this.report.end_date = response.end_date;
            })
            .catch((err) => {
                this.loading.campaign = false;
            });
        }   
    },

    async clearDates(){
        this.report.start_date = "";
        this.report.end_date = "";
    }
},

watch: {

    countryTerm: debounce(async function (val: string) {
        if(val){
            //await this.fetchCountrys({term: val});
        }
    }, 500),

    campaignTerm: debounce(async function (val: string) {
        if(val){
            await this.fetchCampaing({term: val});
        }
    }, 500),

    listTerm: debounce(async function (val: string) {
        if(val){
            //await this.fetchListPOIS({term: val});
        }
    }, 500),

    async "report.campaign_id"(val){
        this.errors.campaign_id = [];
        if(this.searchDates){
            if(isEmpty(val)) {
                this.clearDates();
                return;
            };
            await this.searchDatesFromCampaign(val);
        }
        if(this.isMultiSelected('campaign')){
            await this.handleSelectedItem("campaign", val);
        }
    },

    async "report.country_id"(val){
        this.errors.country_id = [];
        if(this.isMultiSelected('country')){
            await this.handleSelectedItem("country", val);
        }
    },

    async "report.list_id"(val){
        if(this.isMultiSelected('listPois')){
            await this.handleSelectedItem("list_pois", val);
        }
        this.errors.country_id = [];
    }
},
    
});